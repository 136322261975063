import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import FindCUHero from 'components/FindCU/Hero';
import FindCUBody from 'components/FindCU/Body';

const FindACreditUnion: React.FC = () => {
  return (
    <Layout>
      <SEO title="Find your Credit Union Partner" />
      <FindCUHero />
      <FindCUBody />
    </Layout>
  );
};

export default FindACreditUnion;
